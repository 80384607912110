// ** Initial State
const initialState = { data: [], headers: {}, dataLoading: false }

const historyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_HISTORY_ITEMS':
            return {
                ...state,
                data: action.data,
                headers: action.headers,
                dataLoading: false
            }
        case 'GET_HISTORY_ITEMS_EXPORT':
            return {
                ...state,
                data: action.data
            }
        case 'START_HISTORY_LOADER':
            return {
                ...state,
                dataLoading: action.data
            }
        default:
            return state
    }
}

export default historyReducer
