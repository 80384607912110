// ** Initial State
const initialState = { data: [], organizationMember: [], headers: {} }

const organizationMembersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MEMBERS':
            return {
                organizationMember: action.data,
                headers: action.headers
            }
        case 'GET_DOWNLOAD_FILE':
            return action.data
        default:
            return state
    }
}

export default organizationMembersReducer
