// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import menu from './menu'
import user from './user'
import contacts from './contact'
import profile from './profile'
import publicProfile from './public-profile'
import home from './home'
import histories from './history'
import settings from './settings'
import blacklist from './blacklist'
import meeting from './meeting-rooms'
import organization from './organization'
import availability from './availability'
import organizationMembers from './organization-members'
import reseller from './reseller'
import payment from './payment'
import others from './others'
import signUp from './sign-up'
import blockedCallers from './blocked-callers'
import question from './question'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  menu,
  user,
  home,
  histories,
  profile,
  publicProfile,
  contacts,
  settings,
  blacklist,
  meeting,
  organization,
  organizationMembers,
  availability,
  reseller,
  payment,
  others,
  signUp,
  blockedCallers,
  question
})

export default rootReducer
