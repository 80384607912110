// ** Initial State
const initialState = { data: [], headers: {} }

const resellerReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_RESELLER_ORG':
            return {
                ...state,
                data: action.data
            }
            case 'GET_PROMO_CODES':
                return {
                    ...state,
                    data: action.data
                }
    
        default:
            return state
    }
}

export default resellerReducer
