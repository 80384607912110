// ** Initial State
const initialState = { availability: {}, schedule: {} }

const availabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_AVAILABILITY':
            return {
                ...state,
                availability: action.data
            }
        case 'GET_AVAILABILITY_SCHEDULE':
            return {
                ...state,
                schedule: action.data
            }
        default:
            return state
    }
}

export default availabilityReducer

