/* global AUTHORIZATION */
/* global IISIGHT_BASE_URL */

import axios from 'axios'

export const getApiBaseUrl = () => {
  return window?.location?.hostname === 'localhost' ? 'https://api.pracxel.com/' : 'https://api.pracxel.com/'
  // return window?.location?.hostname === 'localhost' ? 'http://127.0.0.1:7445/' : 'http://127.0.0.1:7445/'
}

axios.defaults.baseURL = getApiBaseUrl()
axios.defaults.headers = {
  'Content-Type': 'application/json',
}