// ** Initial State
const initialState = { rooms: {} }

const meetingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROOMS':
            return {
                ...state,
                rooms: action.data
            }
        default:
            return state
    }
}

export default meetingReducer
